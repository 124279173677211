import Cookies from "js-cookie";

// -----------------------------------------
// 変数
// -----------------------------------------
//フォントサイズ
let large = "78.5%", //20px相当
  middle = "70.5%", //18px相当
  small = "62.5%"; //16px相当

//カラー
let colorText = toRgb("#1c1a1a"), //テキストカラー
  colorTextReversal = toRgb("#fdfdfd"),
  colorBodyD = toRgb("#303636"), //テキストカラー
  colorHighlightL = toRgb("#e8e9e9"), //ハイライトカラー
  colorHighlightD = toRgb("#454a4a"), //ハイライトカラー
  colorGrayL = toRgb("#757575"), //グレースケール
  colorGrayD = toRgb("#dedede"),
  colorInvertL = "none",
  colorInvertD = "invert(100%)"; //グレースケール

//保存期間
let date = 30;

// -----------------------------------------
// RGBに変換
// -----------------------------------------

function toRgb(color) {
  const red = parseInt(color[1] + color[2], 16);
  const green = parseInt(color[3] + color[4], 16);
  const blue = parseInt(color[5] + color[6], 16);
  const rgb = red + "," + green + "," + blue;
  return rgb;
}

// -----------------------------------------
// フォントサイズ変更
// -----------------------------------------
function sizeSwitch(val) {
  switch (val) {
    case "sizeLarge":
      document.documentElement.style.setProperty("--fontSize", large);
      break;
    case "sizeMiddle":
      document.documentElement.style.setProperty("--fontSize", middle);
      break;
    default:
      document.documentElement.style.setProperty("--fontSize", small);
  }
}

// -----------------------------------------
// テーマカラー変更
// -----------------------------------------
function colorSwitch(val) {
  switch (val) {
    case "colorDark":
      document.documentElement.style.setProperty(
        "--colorText",
        colorTextReversal
      );
      document.documentElement.style.setProperty(
        "--colorTextReversal",
        colorText
      );
      document.documentElement.style.setProperty("--colorBody", colorBodyD);
      document.documentElement.style.setProperty(
        "--colorHighlight",
        colorHighlightD
      );
      document.documentElement.style.setProperty(
        "--colorGrayScale",
        colorGrayD
      );
      document.documentElement.style.setProperty("--invert", colorInvertD);
      break;
    default:
      document.documentElement.style.setProperty("--colorText", colorText);
      document.documentElement.style.setProperty(
        "--colorTextReversal",
        colorTextReversal
      );
      document.documentElement.style.setProperty(
        "--colorBody",
        colorTextReversal
      );
      document.documentElement.style.setProperty(
        "--colorHighlight",
        colorHighlightL
      );
      document.documentElement.style.setProperty(
        "--colorGrayScale",
        colorGrayL
      );
      document.documentElement.style.setProperty("--invert", colorInvertL);
  }
}

document.addEventListener("DOMContentLoaded", function () {
  const fontsize = Cookies.get("fontsize");
  const themeColor = Cookies.get("themeColor");
  // const color = $(
  //   'input:radio[name="themeColor"]:not(:checked) + label'
  // ).text();
  // const size = $('input:radio[name="fontsize"]:not(:checked) + label').text();
  //クッキーが保存されている場合
  if (fontsize !== undefined || themeColor !== undefined) {
    sizeSwitch(fontsize);
    colorSwitch(themeColor);
    $("#" + fontsize).prop("checked", true);
    $("#" + themeColor).prop("checked", true);
    //クッキーが保存されていない場合のみ読み上げ
    if (fontsize !== undefined) {
      $(".consoleContainer").attr("aria-hidden", "true");
    }
    //ダークモードだった時の処理
  } else if (
    window.matchMedia("(prefers-color-scheme: dark)").matches == true
  ) {
    colorSwitch("colorDark");
    $("#colorDark").prop("checked", true);
    if (Cookies.get("agree")) {
      Cookies.set("themeColor", "colorDark", {
        expires: date,
        path: "/",
        // secure: true,
        sameSite: "lax",
      });
    }
  } else {
    // $('input:radio[name="themeColor"]:not(:checked)').attr(
    //   "aria-label",
    //   color + "チェックなし"
    // );
    // if ($("#sizeSmall").prop("checked") === true) {
    //   $("#sizeSmall").attr("aria-label", "");
    // } else {
    //   $("#sizeSmall").attr("aria-label", "小チェックなし");
    // }
    // if ($("#sizeMiddle").prop("checked") === true) {
    //   $("#sizeMiddle").attr("aria-label", "");
    // } else {
    //   $("#sizeMiddle").attr("aria-label", "ちゅうチェックなし");
    // }
    // if ($("#sizeLarge").prop("checked") === true) {
    //   $("#sizeLarge").attr("aria-label", "");
    // } else {
    //   $("#sizeLarge").attr("aria-label", "大チェックなし");
    // }
  }
  console.log("表示設定：" + fontsize + themeColor);
});

// -----------------------------------------
// フォントサイズ変更
// -----------------------------------------
$(function () {
  $('input:radio[name="fontsize"]').on("change", function () {
    //chekedがついてるidを変数に格納
    const fontsize = $('input:radio[name="fontsize"]:checked').attr("id");
    //ラベル取得して、チェックついていない方にチェックなしの読み上げ追加
    // if ($("#sizeSmall").prop("checked") === true) {
    //   $("#sizeSmall").attr("aria-label", "");
    // } else {
    //   $("#sizeSmall").attr("aria-label", "小チェックなし");
    // }
    // if ($("#sizeMiddle").prop("checked") === true) {
    //   $("#sizeMiddle").attr("aria-label", "");
    // } else {
    //   $("#sizeMiddle").attr("aria-label", "ちゅうチェックなし");
    // }
    // if ($("#sizeLarge").prop("checked") === true) {
    //   $("#sizeLarge").attr("aria-label", "");
    // } else {
    //   $("#sizeLarge").attr("aria-label", "大チェックなし");
    // }
    $('input:radio[name="themeColor"]:checked').attr("aria-label", "");
    if (Cookies.get("agree")) {
      // cookieに格納 expiresで有効日数指定 pathで使用するディレクトリ指定
      Cookies.set("fontsize", fontsize, {
        expires: date,
        path: "/",
        // secure: true,
        sameSite: "lax",
      });
    }
    //chekedがついてるidに応じてrootのフォントサイズを変更
    var headerHight = $("header").delay(2000).height();
    $("main").css({ "margin-top": headerHight });
    sizeSwitch(fontsize);
  });
});

// -----------------------------------------
// テーマカラー変更
// -----------------------------------------
$(function () {
  $('input:radio[name="themeColor"]').on("change", function () {
    //chekedがついてるidを変数に格納
    const themeColor = $('input:radio[name="themeColor"]:checked').attr("id");
    //ラベル取得して、チェックついていない方にチェックなしの読み上げ追加
    // const color = $(
    //   'input:radio[name="themeColor"]:not(:checked) + label'
    // ).text();
    // $('input:radio[name="themeColor"]:not(:checked)').attr(
    //   "aria-label",
    //   color + "チェックなし"
    // );
    $('input:radio[name="themeColor"]:checked').attr("aria-label", "");
    //同意していたらクッキーに設定を格納
    if (Cookies.get("agree")) {
      Cookies.set("themeColor", themeColor, {
        expires: date,
        path: "/",
        // secure: true,
        sameSite: "lax",
      });
    }
    colorSwitch(themeColor);
  });
});

// -----------------------------------------
// クッキー削除
// -----------------------------------------
$(function () {
  $("#clearColor").on("click", function () {
    Cookies.remove("themeColor", {
      path: "/",
    });
    Cookies.remove("agree", {
      path: "/",
    });
    sessionStorage.removeItem("visit");
    console.log(Cookies.get("themeColor"));
    console.log(Cookies.get("agree"));
  });
  $("#clearSize").on("click", function () {
    Cookies.remove("fontsize", { path: "/" });
    console.log(Cookies.get("fontsize"));
  });
});

$(function () {
  //iphoneの時
  if (navigator.userAgent.match(/(iPhone|iPad|iPod)/i)) {
    $(".-required").attr("aria-hidden", "false");
  } else {
    return false;
  }
});

$(function () {
  //セッションストレージがセットされていない場合はheaderを読み上げる
  if (!sessionStorage.getItem("visit")) {
    $("#header").attr("aria-hidden", "false");
    //セッションストレージをセット
    sessionStorage.setItem("visit", "true");
  } else {
    //セッションストレージがセットされている場合は読み上げない
    $("#header").attr("aria-hidden", "true");
  }
  $(".skipNav").on("click", function () {
    $("#header").attr("aria-hidden", "false");
  });
});

//NetReaderⅡの時だけ二重読み防止のため表示専用テキスト消す
$(function () {
  const ua = navigator.userAgent;
  if (ua.indexOf("Trident") !== -1) {
    $(".leadOnly").remove();
  }
});
