// import "lity";
// import 'drawsvg';
// import particles from "particles.js";
// import "slick-carousel";
import "./utils/opening";
import "./utils/accessibility";
import "./utils/style";
import "./utils/accordion";
import smoothscroll from "smoothscroll-polyfill";
smoothscroll.polyfill();
// import moive from "./utils/movie";
// import objectFitImages from 'object-fit-images';
// objectFitImages();
