// // アコーディオン
$(function () {
  // var ww = window.innerWidth;
  $(".pullDown > .pull-btn").on("click", function () {
    $(this).next().slideToggle(600, "swing");
    $(this).toggleClass("active");
    if ($(this).attr("aria-expanded") === "false") {
      $(this).attr({ "aria-expanded": "true" });
    } else {
      $(this).attr({ "aria-expanded": "false" });
    }
    return false;
  });
  $(".pull-inner > .pull-close").on("click", function () {
    $(this).parent().slideToggle(600, "swing");
    $(this).parent().prev().toggleClass("active");
    return false;
  });
  // if (ww < 767) {
  //   $(".sp_pull > p").on("click", function () {
  //     $(this).next().slideToggle(600, "swing");
  //     $(this).toggleClass("active");
  //     return false;
  //   });
  // }
});

// //faq
// $(function () {
//   $(".faqWrapper dt").on("click", function () {
//     $(this).next().slideToggle(600, "swing");
//     $(this).toggleClass("open");
//     return false;
//   });
// });
