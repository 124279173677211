import "jquery-match-height";
// window.addEventListener("load", function () {
//   // var headerHight = 0;
//   var headerHight = $("header").height();
//   $("main").css({ "margin-top": headerHight });

//   $('a[href^="#"]:not(.skipLink)').on("click", function (e) {
//     var href = $(this).attr("href");
//     var target = $(href == "#" || href == "" ? "html" : href);
//     var position = target.offset().top - headerHight;

//     $.when(
//       $("html, body").animate(
//         {
//           scrollTop: position,
//         },
//         400,
//         "swing"
//       ),
//       e.preventDefault()
//     ).done(function () {
//       var diff = target.offset().top - headerHight;
//       if (!diff === position) {
//         $("html, body").animate(
//           {
//             scrollTop: diff,
//           },
//           10,
//           "swing"
//         );
//       }
//     });
//   });
// });

//TOPに戻るボタン
$(function () {
  var showFlag = false;
  var topBtn = $("#toTop");
  topBtn.css("bottom", "-100px");
  var showFlag = false;

  //スクロールが100に達したらボタン表示
  $(window).on("scroll", function () {
    if ($(this).scrollTop() > 100) {
      if (showFlag == false) {
        showFlag = true;
        topBtn.stop().animate({ bottom: "50px" }, 200);
        // $("header").addClass("-fixed");
      }
    } else {
      if (showFlag) {
        showFlag = false;
        topBtn.stop().animate({ bottom: "-100px" }, 200);
        // $("header").removeClass("-fixed");
      }
    }
  });
  //スクロールしてトップ
  $("#toTop").on("click", function () {
    $(this).blur();
  });
});

$(function () {
  let anchor = document.getElementsByClassName("anchor");
  var headerHight = $("header").height();
  $("main").css({ "margin-top": headerHight });

  if (0 < anchor.length) {
    //option
    let scrollOption = {
      behavior: "smooth", //推移のアニメーション
      block: "start", //縦方向のスクロール位置
      inline: "nearest", //横方向のスクロール位置
    };

    let anchorLink = (e) => {
      e.preventDefault();

      var _this = e.currentTarget;
      var _thisTarget;
      if (_this.getAttribute("href") === "#") {
        //TOPへ戻る
        _thisTarget = document.body; //ターゲットをbody等にすればTOPへ戻る
      } else {
        //アンカーリンク
        _thisTarget = document.querySelector(
          _this.getAttribute("href").replace(/[\/\\^$.*+?()[\]{}|]/g, "\\$&")
        );
      }
      _thisTarget.scrollIntoView(scrollOption);
      _thisTarget.blur();
    };

    for (let i = 0; i < anchor.length; i++) {
      anchor[i].addEventListener("click", function (e) {
        anchorLink(e);
      });
    }
  }
});

//ハンバーガーメニュー
$(function () {
  var preventScroll = {
    x: 0,
    y: 0,
    setPos: function () {
      this.x = window.pageXOffset;
      this.y = window.pageYOffset;
    },
    handleEvent: function () {
      window.scrollTo(this.x, this.y);
    },
    enable: function () {
      this.setPos();
      window.addEventListener("touchmove", this, { passive: false });
    },
    disable: function () {
      window.removeEventListener("touchmove", this);
    },
  };
  var ww = window.innerWidth;
  if (ww <= 750) {
    $(document).on("click", ".ac_menu, .skipNav", function () {
      $(".ac_menu").next().toggleClass("-active");
      $(".ac_menu").toggleClass("-active");
      if ($(".ac_menu").attr("aria-expanded") === "false") {
        $(".ac_menu").attr({ "aria-expanded": "true" });
      } else {
        $(".ac_menu").attr({ "aria-expanded": "false" });
      }
      if ($(".ac_menu").hasClass("-active")) {
        preventScroll.enable();
      } else {
        preventScroll.disable();
      }
      return false;
    });
    $("#menuClose").on("click", function () {
      $(".ac_menu").removeClass("-active");
      $(".ac_menu").next().removeClass("-active");
      $(".ac_menu").attr({ "aria-expanded": "false" });
      preventScroll.disable();
    });
  }
});

//検索ボックスに何か入力されたらクリアボタンを表示、それが空白だったら非表示
$(function () {
  if ($(".searchInput").val() !== "") {
    $(".clearBtn").addClass("-active");
  }
  $(".searchInput").on("input", function () {
    if ($(this).val() === "" || $(this).val() === null) {
      $(this).nextAll(".clearBtn").removeClass("-active");
    } else {
      $(this).nextAll(".clearBtn").addClass("-active");
    }
  });
});

//検索ボックスのクリアボタンを押したら値をクリア
$(function () {
  $(".clearBtn").on("click", function () {
    $(this).prevAll(".searchInput").val("");
    $(this).removeClass("-active");
  });
});

$(function () {
  const setFillHeight = () => {
    const vh = window.innerHeight;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  };

  let vw = window.innerWidth;

  window.addEventListener("resize", () => {
    if (vw === window.innerHeight) {
      // 画面の横幅にサイズ変動がないので処理を終える
      return;
    }

    // 画面の横幅のサイズ変動があった時のみ高さを再計算する
    vw = window.innerWidth;
    setFillHeight();
  });

  // 初期化
  setFillHeight();
});

$(function () {
  $(".has-child > button").on("click", function (e) {
    var ww = window.innerWidth;
    if (ww <= 750) {
      $(this).nextAll("ul").slideToggle();
      $(this).parent().toggleClass("pullOpen");
      e.preventDefault();
      return false;
    }
  });
});

//マッチハイト
// $(window).on("load resize", function () {
//   var target_img = $(".cardBox img");
//   target_img.on("load", function () {
//     $(".cardBox").matchHeight();
//   });
// });
// $(function () {
//   $(".cardBox").matchHeight();
// });
// $(window).on("load resize", function () {
//   var target_img = $(".-tile img");
//   target_img.on("load", function () {
//     $(".-tile").matchHeight();
//   });
// });
// $(function () {
//   $(".-tile").matchHeight();
// });

$(function () {
  var ww = window.innerWidth;
  if (ww <= 750) {
    $("article.columnBlock").matchHeight();
  }
});

$(function () {
  $(".skipLink").focus(function () {
    $(this).addClass("show");
  });
  $(".skipLink").on("click", function () {
    $(this).removeClass("show").blur();
  });
});

//lazyloadのカクつき防止
// $(window).on("load", function () {
//   var target_img = $("img[loading='lazy']");
//   target_img.css("opacity", "0");
//   target_img.on("load", function () {
//     target_img.animate({ opacity: "1" }, { duration: 600, easing: "swing" });
//   });
// });
